import { start } from "single-spa";
import { constructApplications, constructRoutes, constructLayoutEngine } from "single-spa-layout";
import { getDynamicTemplate } from "./dynamic-template";
import { createSWOAppRegistrator } from "./swo-app";
import { getShellProps } from "./shell-props";
import { getAppsData } from './app-data';
import { createAutoLogin } from './auth';
import { createLoggerFactory } from './logger';
import setupServiceWorker from './service-worker';

import './main.scss';
import './layout.scss';
import { setupGA } from "./ga";


(async () => {
  const getLogger = createLoggerFactory(__LOG_LEVEL__);
  setupServiceWorker(__SERVICE_WORKER__, getLogger);
  setupGA(__GA_ID__);

  const [appsData, shellProps] = await Promise.all([getAppsData(), getShellProps(getLogger)]);
  const registerSWOApp = createSWOAppRegistrator(shellProps);

  const layoutTemplate = getDynamicTemplate(appsData);

  const routes = constructRoutes(layoutTemplate);

  const autoLogin = createAutoLogin(appsData, shellProps.auth0Client, getLogger);

  const applications = constructApplications({
    routes,
    loadApp: async ({ name }) => {
      await autoLogin(name);
      return System.import(name);
    }
  });

  const layoutEngine = constructLayoutEngine({ routes, applications });

  applications.forEach(registerSWOApp);
  layoutEngine.activate();
  start();
})();
